<template>
  <div>
    <a-modal
      :visible.sync="show"
      :title="title"
      destroyOnClose
      width="600px"
      @cancel="close"
      @ok="handleOk"
    >
      <a-form :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }">
        <!-- <a-form-item label="订阅活动类别">
            产品预约订阅
        </a-form-item> -->
        
        <a-form-item label="内部名" class="mb-2">
          <a-input placeholder="内部名（用户不可见）" v-model="form.name_interior" />
        </a-form-item>
        <a-form-item label="推送时间" class="mb-2">
          <a-date-picker style="width:100%" placeholder="推送时间" 
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm" 
            v-model="form.push_time" />
        </a-form-item>
        <a-form-item label="点击消息打开小程序页面" class="mb-2">
          <a-input placeholder="小程序页面地址，形如 pages/xx" v-model="form.wx_push_template_to_url" />
        </a-form-item>
        <div class="notice-tips">* 以下是用户会收到的微信服务通知的内容</div>
        <a-form-item label="商品名称(消息标题)" class="mb-2">
          <a-input placeholder="订阅活动名(20字以内)" v-model="form.name" />
        </a-form-item>
        <a-form-item label="备注(消息备注)" class="mb-2">
          <a-input placeholder="消息备注(20字以内)" v-model="form.wx_push_template_remarks" />
        </a-form-item>
        <a-form-item label="开售时间" class="mb-2">
          <a-date-picker style="width:100%" placeholder="开售时间" 
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm" 
            v-model="form.sale_start_time" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { 
  addWxSubscribeList,
  updateWxSubscribeList,
} from "@/api/activity/wx-subscribe.js"
import moment from "moment"

export default {
  props: {
    show: {
      type: Boolean,
    },
    activeRow: {
      type: Object,
    },
    type:{
      type: String
    }
  },
  name: "wechatActivityModel",

  data() {
    return {
      title:"新增",
      form: {
        type: 1,
        name: '',
        name_interior: '',
        wx_push_template_remarks: '',
        wx_push_template_to_url: '',
        sale_start_time: null,
        push_time: null,
      },
    };
  },

  mounted() {
    this.initData();
  },

  methods: {
    initData() {
      if(this.type == 'add'){
        this.title = '新增'
      } else {
        this.title = '编辑'
        this.form = {
          id: this.activeRow.id,
          type: this.activeRow.type,
          name: this.activeRow.name,
          name_interior: this.activeRow.name_interior,
          wx_push_template_remarks: this.activeRow.wx_push_template_remarks,
          wx_push_template_to_url: this.activeRow.wx_push_template_to_url,
          sale_start_time: this.activeRow.sale_start_time ? moment(this.activeRow.sale_start_time) : null,
          push_time: this.activeRow.push_time ? moment(this.activeRow.push_time) : null,
        }
      }
    },
    async handleOk() {
      if(!this.form.name){
        return this.$message.warning('请输入订阅活动名');
      }
      if(!this.form.name_interior){
        return this.$message.warning('请输入内部名');
      }
      if(!this.form.sale_start_time){
        return this.$message.warning('请选择开售时间');
      }
      if(!this.form.push_time){
        return this.$message.warning('请选择计划推送时间');
      }

      if((this.form.name).length > 20){
        return this.$message.warning('活动名不能超20字');
      }
      if((this.form.wx_push_template_remarks).length > 20){
        return this.$message.warning('副标题备注不能超20字');
      }

      const params = Object.assign({}, this.form, {
        sale_start_time: moment(this.form.sale_start_time).format("YYYY-MM-DD HH:mm"),
        push_time: moment(this.form.push_time).format("YYYY-MM-DD HH:mm"),
      })
      if(this.type == "add"){
        const {code} = await addWxSubscribeList(params)
        if(code == 0){
          this.$message.success('已提交');
          this.$emit("ok");
          this.close()
        }
      }
      if(this.type == "edit"){
        const {code} = await updateWxSubscribeList(params)
        if(code == 0){
          this.$message.success('已提交');
          this.$emit("ok");
          this.close()
        }
      }
    },

    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="less" scoped>
.notice-tips{
  border-top: 1px solid #d1d1d1;
  padding: 15px 0 15px;
  text-align: center;
  color: #999;
  font-size: 12px;
}
</style>