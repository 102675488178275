<template>
  <div>
    <base-table
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #toolbar>
        <div class="flex justify-between">
          <div class="flex">
            <h4 class="text-lg font-bold">微信活动订阅</h4>
            <a-button class="ml-4 p-0" type="link" @click="initData">刷新列表</a-button>
          </div>
          <a-button class="ml-3" type="primary" @click="handlerEdit('add')">新增</a-button>
        </div>
      </template>
      <template #operation="{record}">
        <div class="flex justify-around">
          <a-button class="p-0" type="link" @click="handlerEdit('edit',record)">修改</a-button>
        </div>
      </template>
    </base-table>
    <wechat-activity-model
      v-if="isShowModel"
      :activeRow="activeRow"
      :show.sync="isShowModel"
      :type="modelType"
      @ok="initData"
    />
  </div>
</template>

<script>
import { 
  getWxSubscribeList
} from "@/api/activity/wx-subscribe.js"
import pageData from "./columns"
import wechatActivityModel from './components/wechat-activity-model.vue';

export default {
  components: { wechatActivityModel },

  data() {
    return {
      ...pageData,

      rowKey: "id",
      tableData: [],
      total: 0,
      searchForm:{
        page: 1,
        page_count: 20
      },

      activeRow: {},
      modelType: 'add',
      isShowModel: false,
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
    async initData(){
      this.tableData = []
      this.total = 0
      
      const {code, data} = await getWxSubscribeList()
      if(code == 0){
        this.tableData = data.list
        this.total = data.total_count
      }
    },
    handlerEdit(type, row){
      this.modelType = type
      this.activeRow = row
      this.isShowModel = true
    }
  },
};
</script>

<style lang="scss" scoped>

</style>