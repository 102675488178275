export const columns = [
  // {
  //   title: "类型",
  //   dataIndex: "type",
  //   align: "center",
  // },
  {
    title: "内部名",
    dataIndex: "name_interior",
    align: "center",
  },
  {
    title: "订阅活动名",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "副标题备注",
    dataIndex: "wx_push_template_remarks",
    align: "center",
  },
  {
    title: "小程序页面",
    dataIndex: "wx_push_template_to_url",
    align: "center",
  },
  {
    title: "开售时间",
    dataIndex: "sale_start_time",
    align: "center",
  },
  {
    title: "计划推送时间",
    dataIndex: "push_time",
    align: "center",
  },
  {
    title: "订阅人数",
    dataIndex: "subscription_num",
    align: "center",
    width: 90,
  },
  {
    title: "推送人数",
    dataIndex: "push_num",
    align: "center",
    width: 90,
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 80,
    slots: { customRender: "operation" },
  },
];

export default {
  columns,
};
