import ajax from '@/utils/ajax.js'

// 订阅活动：列表
export function getWxSubscribeList(params) {
  return ajax.post('/WxSubscriptionEvent/getList',params)
}

// 订阅活动：新增
export function addWxSubscribeList(params) {
  return ajax.post('/WxSubscriptionEvent/add',params)
}

// 订阅活动：修改
export function updateWxSubscribeList(params) {
  return ajax.post('/WxSubscriptionEvent/update',params)
}
